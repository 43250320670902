.app {
    position: relative;
    //background: rgba(#ba9999, 0.2);
}

.appBg {
    background: url(./assets/bg-black.png) #323232 center no-repeat;
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 0;
}
